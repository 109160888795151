import React from "react"
import { Box, Stack } from "@chakra-ui/react"
import { Link, graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faHospitalUser,
  faUserPlus,
  faUserMd,
} from "@fortawesome/pro-regular-svg-icons"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  IntroText,
  LabelIcon,
  PageLabel,
  PrimaryPagesHero,
  SectionHeading,
  TwoCols,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import { QuickLinks, Liasons, Seo } from "../../components/elements"

const MedicalProfessionals = ({ data, pageContext }) => {
  const page = data.datoCmsCancerMedicalProf
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </PrimaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container>
            <Box width="full">
              <IntroText>{page.introContent}</IntroText>
            </Box>
            <Stack direction={["column", "column", "row"]} spacing={12}>
              <Box width={["full", "full", "1 / 2"]} alignSelf="center">
                <TwoCols
                  content={`${page.contentNode.childMarkdownRemark.html}`}
                />
              </Box>
            </Stack>
          </Container>
        </Section>

        {/* Refer/Transfer */}
        <Section id="rt-patients" bg="lightgray">
          <Container>
            <SectionHeading textAlign="center">
              For Your Convenience
            </SectionHeading>
            <Stack
              direction={["column", "column", "row"]}
              spacing={6}
              justifyContent="center"
            >
              <Box width={["full", 3 / 4, 1 / 3]} alignSelf="center">
                <Link to="refer-patient" style={{ textDecoration: "none" }}>
                  <PageLabel>
                    <LabelIcon title="Refer a Patient">
                      <FontAwesomeIcon icon={faUserPlus} className="fa-lg" />
                    </LabelIcon>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                  </PageLabel>
                </Link>
              </Box>
              <Box width={["full", 3 / 4, 1 / 3]} alignSelf="center">
                <Link to="transfer-patient" style={{ textDecoration: "none" }}>
                  <PageLabel>
                    <LabelIcon title="Transfer a Patient">
                      <FontAwesomeIcon
                        icon={faHospitalUser}
                        className="fa-lg"
                      />
                    </LabelIcon>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                  </PageLabel>
                </Link>
              </Box>

              {page.specialistDirectory.map(
                ({ title, document, externalLink }) => (
                  <Box width={["full", 3 / 4, 1 / 3]} alignSelf="center">
                    <Link
                      href={document ? document.url : externalLink}
                      style={{ textDecoration: "none" }}
                    >
                      <PageLabel>
                        <LabelIcon title={title}>
                          <FontAwesomeIcon icon={faUserMd} className="fa-lg" />
                        </LabelIcon>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="fa-lg"
                        />
                      </PageLabel>
                    </Link>
                  </Box>
                )
              )}
            </Stack>
          </Container>
        </Section>

        {/* Liasons */}
        
        {page?.liasons > 0 && (
        <Box bg="lightgray" pb={[9, 9, 12]}>
          <Container>
            <SectionHeading textAlign="center" mb={3} id="liasons">
              Physician Liaisons
            </SectionHeading>
            <Box as="p" textAlign="center">
              {page.liasonsIntro}
            </Box>

            <Liasons page={page} />
          </Container>
        </Box>
        )}

        {/* Quicklinks */}
        <Section id="quicklinks">
          <Container>
            <SectionHeading textAlign="center">
              Quick Links
            </SectionHeading>
            <QuickLinks page={page} />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default MedicalProfessionals

export const query = graphql`
  {
    datoCmsCancerMedicalProf {
      id
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      introContent
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      liasons {
        name
        email
        title
        phoneNumber
        photo {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fit: "crop", crop: "focalpoint" }
          )
        }
      }
      liasonsIntro
      quicklinks {
        id
        externalLinkUrl
        internalLinkUrl
        linkTitle
      }
      specialistDirectory {
        externalLink
        id
        title
        document {
          url
        }
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
